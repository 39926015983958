import { useEffect, useState } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlaceIcon from "@mui/icons-material/Place";
import { useTheme } from "@emotion/react";
import { CircularProgress, Typography, useMediaQuery } from "@mui/material";
import Pagination from "@mui/material/Pagination";
import { FaChartLine, FaMapMarkerAlt, FaTag } from "react-icons/fa";

const InfoContainer = ({
  errorRadius,
  currentLocation,
  currentAddress,
  filteredOutlets,
  directions,
  fetchFilteredOutlets,
  radius,
  setRadius,
  wayPoints,
  selectedSortOption,
  setSelectedSortOption,
  selectedFilters,
  setSelectedFilters,
  nonBilledFilters,
  setNonBilledFilters,
  categorySizeFilters,
  setCategorySizeFilters,
  currentOutlets,
  currentPage,
  totalPages,
  handlePageChange,
  handleGetRoute,
  handleSelectOutlet,
  googleMapsLink,
  distance,
  eta,
  duration,
  selectedLeg,
  handleBack,
  selectedOutlet,
  handleOutletClick,
  toggleSection,
  openSection,
  isLoading,
  hasSearched,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prev) => !prev);
  const theme = useTheme();
  const isMediumDown = useMediaQuery(theme.breakpoints.down("md"));

  const handleSort = (e) => {
    setSelectedSortOption(e.target.value);
  };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };

  const handleFilterChange = (filter) => {
    setSelectedFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };
  const handleNonBilledFilterChange = (days) => {
    setNonBilledFilters((prevFilters) => ({
      ...prevFilters,
      [days]: {
        ...prevFilters[days],
        isSelected: !prevFilters[days].isSelected,
      },
    }));
  };

  const handleCategorySizeFilterChange = (range) => {
    setCategorySizeFilters((prevFilters) => ({
      ...prevFilters,
      [range]: !prevFilters[range],
    }));
  };

  return (
    <div
      style={{
        flex: isMediumDown ? "" : 1,
        padding: isMediumDown ? "10px" : "0 20px",
        maxHeight: isMediumDown ? "none" : "100vh",
        overflowY: isMediumDown ? "none" : "auto",
      }}
    >
      <Typography
        style={{
          fontWeight: "bolder",
          fontFamily: "Roboto",
          fontSize: isMediumDown ? "large" : "x-large",
        }}
      >
        {" "}
        Plan Your Visits
      </Typography>
      {currentLocation ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: isMediumDown ? "0px" : "10px",
            marginTop: isMediumDown ? "0px" : "7px",
          }}
        >
          {!isMediumDown && (
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ fontFamily: "Roboto", fontSize: "16px" }}>
                Your Location: {currentAddress}
              </div>
            </div>
          )}

          {!directions && (
            <>
              <div
                style={{
                  display: "flex",
                  gap: "2%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  <div style={{ fontFamily: "Roboto", fontSize: "15px" }}>
                    Set Radius (km):
                    <input
                      style={{ width: "50px" }}
                      type="number"
                      value={radius}
                      onChange={(e) => setRadius(e.target.value)}
                    />
                  </div>
                  {errorRadius && (
                    <div
                      style={{
                        color: "#ff0000",
                        fontSize: "10px",
                        marginTop: "1px",
                        fontWeight: "normal",
                      }}
                    >
                      {errorRadius}
                    </div>
                  )}
                </div>
                <div
                  style={{
                    width: "100px",
                    height: "fit-content",
                    padding: "1% 0",
                    backgroundColor: "#f0f0f0",
                    fontSize: "14px",
                    cursor: "pointer",
                    textAlign: "center",
                    borderRadius: "5px",
                  }}
                  onClick={fetchFilteredOutlets}
                >
                  Find Outlets
                </div>
                {!isMediumDown && (
                  <div>
                    <select
                      value={selectedSortOption}
                      onChange={handleSort}
                      style={{
                        padding: "5%",
                        border: "0px",
                        backgroundColor: "#f0f0f0",
                        borderRadius: "5px",
                      }}
                    >
                      <option value="distance">Distance</option>
                      <option value="maxSales">Sales</option>
                      <option value="probability">Likelihood</option>
                      <option value="opportunity">Opportunity</option>
                    </select>
                  </div>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  padding: "2% 0",
                  flexDirection: "column",
                }}
              >
                {isMediumDown ? (
                  <div
                    style={{
                      display: "flex",
                      width: "95%",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        padding: "5px 9px",
                        backgroundColor: "#f0f0f0",
                        fontSize: "14px",
                        borderRadius: "5px",
                      }}
                      onClick={toggleModal}
                    >
                      Filters
                    </div>
                    <div>
                      <select
                        value={selectedSortOption}
                        onChange={handleSort}
                        style={{
                          padding: "5%",
                          border: "0px",
                          backgroundColor: "#f0f0f0",
                          borderRadius: "5px",
                          fontFamily: "Roboto",
                        }}
                      >
                        <option value="distance">Distance</option>
                        <option value="maxSales">Sales</option>
                        <option value="probability">Likelihood</option>
                        <option value="opportunity">Opportunity</option>
                      </select>
                    </div>

                    {isModalOpen && (
                      <div
                        style={{
                          position: "fixed",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          width: "300px",
                          background: "white",
                          border: "1px solid #ccc",
                          borderRadius: "8px",
                          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                          zIndex: 1000,
                          padding: "15px",
                        }}
                      >
                        <div
                          style={{
                            marginTop: 0,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                          }}
                        >
                          <div
                            style={{ fontSize: "15px", fontFamily: "Roboto" }}
                          >
                            Select Filters
                          </div>
                          <button
                            onClick={toggleModal}
                            style={{
                              padding: "8px 16px",
                              color: "black",
                              border: "none",
                              borderRadius: "4px",
                              cursor: "pointer",
                              fontFamily: "Roboto",
                            }}
                          >
                            Close
                          </button>
                        </div>
                        {Object.keys(selectedFilters).map((filter) => (
                          <label
                            key={filter}
                            style={{
                              display: "flex",
                              marginBottom: "10px",
                              fontSize: "14px",
                              fontFamily: "Roboto",
                              alignItems: "center",
                            }}
                          >
                            <input
                              type="checkbox"
                              checked={selectedFilters[filter]}
                              onChange={() => handleFilterChange(filter)}
                              style={{ width: "12px", height: "12px" }}
                            />
                            {filter.charAt(0).toUpperCase() + filter.slice(1)}
                          </label>
                        ))}
                        {selectedFilters.categorySize && (
                          <div
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#f0f0f0",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {Object.keys(categorySizeFilters).map((range) => (
                                <label
                                  key={range}
                                  style={{
                                    marginBottom: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={categorySizeFilters[range]}
                                    onChange={() =>
                                      handleCategorySizeFilterChange(range)
                                    }
                                    style={{ width: "12px", height: "12px" }}
                                  />
                                  <span
                                    style={{
                                      margin: "0px 8px",
                                      fontSize: "13px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {range}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </div>
                        )}
                        {selectedFilters.nonBilled && (
                          <div
                            style={{
                              marginTop: "10px",
                              backgroundColor: "#f0f0f0",
                              borderRadius: "10px",
                              padding: "10px",
                            }}
                          >
                            <div style={{ display: "flex", flexWrap: "wrap" }}>
                              {Object.keys(nonBilledFilters).map((days) => (
                                <label
                                  key={days}
                                  style={{
                                    marginBottom: "10px",
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <input
                                    type="checkbox"
                                    checked={nonBilledFilters[days].isSelected}
                                    onChange={() =>
                                      handleNonBilledFilterChange(days)
                                    }
                                    style={{ width: "12px", height: "12px" }}
                                  />
                                  <span
                                    style={{
                                      margin: "0px 8px",
                                      fontSize: "13px",
                                      fontFamily: "Roboto",
                                    }}
                                  >
                                    {days}
                                  </span>
                                </label>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      width: "100%",
                      flexDirection: "column",
                    }}
                  >
                    <div style={{ display: "flex", fontFamily: "Roboto" }}>
                      Filters :
                      {Object.keys(selectedFilters).map((filter) => (
                        <label
                          key={filter}
                          style={{
                            marginLeft: "10px",
                            fontSize: "14px",
                            fontFamily: "Roboto",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedFilters[filter]}
                            onChange={() => handleFilterChange(filter)}
                            style={{ width: "12px", height: "12px" }}
                          />
                          {filter.charAt(0).toUpperCase() + filter.slice(1)}
                        </label>
                      ))}
                    </div>
                    {selectedFilters.categorySize && (
                      <div
                        style={{
                          marginTop: "10px",
                          padding: "10px",
                          backgroundColor: "#f0f0f0",
                          borderRadius: "10px",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {Object.keys(categorySizeFilters).map((range) => (
                            <label
                              key={range}
                              style={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={categorySizeFilters[range]}
                                onChange={() =>
                                  handleCategorySizeFilterChange(range)
                                }
                                style={{ width: "12px", height: "12px" }}
                              />
                              <span
                                style={{
                                  margin: "0px 8px",
                                  fontSize: "13px",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {range}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    )}
                    {selectedFilters.nonBilled && (
                      <div
                        style={{
                          marginTop: "10px",
                          backgroundColor: "#f0f0f0",
                          borderRadius: "10px",
                          padding: "10px",
                        }}
                      >
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {Object.keys(nonBilledFilters).map((days) => (
                            <label
                              key={days}
                              style={{
                                marginBottom: "10px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <input
                                type="checkbox"
                                checked={nonBilledFilters[days].isSelected}
                                onChange={() =>
                                  handleNonBilledFilterChange(days)
                                }
                                style={{ width: "12px", height: "12px" }}
                              />
                              <span
                                style={{
                                  margin: "0px 8px",
                                  fontSize: "13px",
                                  fontFamily: "Roboto",
                                }}
                              >
                                {days}
                              </span>
                            </label>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}

          {!isMediumDown ? (
            directions ? (
              <div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "3%",
                  }}
                >
                  <div>Route Overview</div>
                  <button
                    style={{
                      padding: "1%",
                    }}
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    back
                  </button>
                </div>

                <div
                  style={{
                    display: "flex",
                    padding: "2%",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "5px",
                    }}
                  >
                    {wayPoints.length > 0 && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FiberManualRecordIcon />
                        <img alt="" src={require("../assets/threedots.png")} />
                      </div>
                    )}
                    <div
                      style={{
                        width: "250px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "fit-content",
                        backgroundColor: "lightgray",
                        padding: "1% 2%",
                        borderRadius: "11px",
                      }}
                    >
                      Your Location
                    </div>
                  </div>

                  {wayPoints.map((outlet, index) => (
                    <div
                      key={outlet.Name}
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        gap: "5px",
                      }}
                    >
                      {index < wayPoints.length - 1 ? (
                        <div
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <FiberManualRecordIcon />
                          <img
                            alt=""
                            src={require("../assets/threedots.png")}
                          />
                        </div>
                      ) : (
                        <PlaceIcon />
                      )}
                      <div
                        style={{
                          width: "250px",
                          textOverflow: "ellipsis",
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          height: "fit-content",
                          backgroundColor: "lightgray",
                          padding: "1% 2%",
                          borderRadius: "11px",
                          cursor: "pointer",
                        }}
                        onClick={() => handleOutletClick(outlet, index)}
                      >
                        {outlet.Name}
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  style={{ padding: "3%", fontSize: "15px", fontWeight: 500 }}
                >
                  <div style={{ display: "flex", fontFamily: "Roboto" }}>
                    Total Travel Distance:
                    <div
                      style={{
                        fontWeight: 600,
                        color: "rgb(11 134 153)",
                        marginLeft: "4px",
                      }}
                    >
                      {" "}
                      {distance}{" "}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingTop: "2%",
                      display: "flex",
                      flexWrap: "wrap",
                      fontFamily: "Roboto",
                    }}
                  >
                    Estimated Drive Time:{" "}
                    <div
                      style={{
                        fontWeight: 600,
                        color: "rgb(11 134 153)",
                        marginLeft: "4px",
                      }}
                    >
                      {duration}
                    </div>
                  </div>
                  <div
                    style={{
                      paddingTop: "2%",
                      display: "flex",
                      flexWrap: "wrap",
                      fontFamily: "Roboto",
                    }}
                  >
                    Expected Trip Completion:{" "}
                    <div
                      style={{
                        fontWeight: 600,
                        color: "rgb(11 134 153)",
                        marginLeft: "4px",
                      }}
                    >
                      {eta}
                    </div>
                  </div>
                </div>
                {selectedOutlet && (
                  <div style={{ marginTop: "20px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                      onClick={() => toggleSection("table")}
                    >
                      <h3>{selectedOutlet.Name}</h3>
                      {openSection === "table" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                    {openSection === "table" && (
                      <TableContainer component={Paper}>
                        <Table>
                          <TableBody>
                            <TableRow>
                              <TableCell>Category Size</TableCell>
                              <TableCell>{selectedOutlet.Category}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Last Bill Date</TableCell>
                              <TableCell>
                                {new Date(
                                  selectedOutlet.Last_Order_Date
                                ).toLocaleDateString()}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Bill Cuts (12 months)</TableCell>
                              <TableCell>
                                {selectedOutlet.Total_Orders}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>Bill Value (12 months)</TableCell>
                              <TableCell>
                                ₹
                                {parseFloat(
                                  selectedOutlet.Total_Sales
                                ).toLocaleString("en-IN")}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>No of Days of No Billing</TableCell>
                              <TableCell>
                                {Math.floor(
                                  (new Date() -
                                    new Date(selectedOutlet.Last_Order_Date)) /
                                    (1000 * 60 * 60 * 24)
                                )}{" "}
                                days
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </div>
                )}
                {selectedLeg && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        cursor: "pointer",
                        borderRadius: "4px",
                      }}
                      onClick={() => toggleSection("steps")}
                    >
                      <h3>Steps to Reach {selectedOutlet?.Name}</h3>
                      {openSection === "steps" ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                    {openSection === "steps" && (
                      <div>
                        <div>
                          <strong>Start Address:</strong>{" "}
                          {selectedLeg.start_address}
                        </div>
                        <div>
                          <strong>Destination:</strong>{" "}
                          {selectedLeg.end_address}
                        </div>
                        <ul>
                          {selectedLeg.steps.map((step, idx) => (
                            <li
                              key={idx}
                              dangerouslySetInnerHTML={{
                                __html: step.instructions,
                              }}
                            ></li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                )}
                {googleMapsLink && (
                  <a
                    href={googleMapsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: "underline",
                      color: "rgb(11 134 153)",
                      fontWeight: "bold",
                    }}
                  >
                    Open in Google Maps
                  </a>
                )}
              </div>
            ) : isLoading ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                }}
              >
                <CircularProgress size="2rem" sx={{ color: "red" }} />
              </div>
            ) : filteredOutlets?.length === 0 && hasSearched ? (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "100%",
                  color: "red",
                  fontSize: "20px",
                  fontWeight: "bolder",
                }}
              >
                No outlets found
              </div>
            ) : (
              filteredOutlets.length !== 0 && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    Outlets :
                    {wayPoints.length !== 0 && (
                      <button
                        style={{
                          padding: "1%",
                        }}
                        onClick={handleGetRoute}
                      >
                        Get Route
                      </button>
                    )}
                  </div>
                  {currentOutlets?.map((outlet) => (
                    <div
                      key={outlet.Name}
                      style={{
                        border: wayPoints?.includes(outlet)
                          ? "2px solid black"
                          : "1px solid #ddd",
                        padding: "6px 9px 9px",
                        margin: "8px 0",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      {/* Header: Outlet Name & Checkbox */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong style={{ fontSize: "16px", color: "#333" }}>
                          {outlet.Name}
                        </strong>
                        <input
                          type="checkbox"
                          checked={wayPoints?.includes(outlet)}
                          onChange={() => handleSelectOutlet(outlet)}
                          style={{
                            width: "15px",
                            height: "15px",
                            cursor: "pointer",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          color: "#444",
                          lineHeight: "1",
                        }}
                      >
                        <p>
                          <FaMapMarkerAlt
                            style={{ color: "#ff5733", marginRight: "5px" }}
                          />{" "}
                          Distance: <strong>{outlet.Distance}</strong> | Last
                          Order: <strong>{outlet.Last_Order_Date}</strong>
                        </p>
                        <p>
                          <FaChartLine
                            style={{ color: "#27ae60", marginRight: "5px" }}
                          />{" "}
                          Probability:{" "}
                          <strong>{outlet.orderProbability}%</strong> | Next
                          Order: <strong>₹{outlet.averageSale}</strong>{" "}
                        </p>
                        <div>
                          <FaTag
                            style={{ color: "#8e44ad", marginRight: "5px" }}
                          />{" "}
                          Category: <strong>{outlet.Category}</strong> | YTD:{" "}
                          <strong>₹{formatNumber(outlet.Total_Sales)}</strong>
                        </div>
                      </div>
                    </div>
                  ))}
                  {filteredOutlets?.length > 0 &&
                    totalPages &&
                    totalPages > 1 && (
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    )}
                </div>
              )
            )
          ) : (
            <></>
          )}
        </div>
      ) : (
        <p>{"Fetching your location..."}</p>
      )}
    </div>
  );
};
export default InfoContainer;

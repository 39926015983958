import {
  GoogleMap,
  Marker,
  DirectionsRenderer,
  useLoadScript,
} from "@react-google-maps/api";
import { useEffect, useState } from "react";

const MapContainer = ({
  currentLocation,
  filteredOutlets,
  directions,

}) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });
  const [markers, setMarkers] = useState([]);
  const [directionsState, setDirectionsState] = useState(null);
  const [currentLocationState, setCurrentLocationState] = useState(null);
  useEffect(() => {
    if (isLoaded) {
      if (directions) {
        setMarkers((prevMarkers) =>
          prevMarkers.length > 0 ? [] : prevMarkers
        );
      } else {
        const newMarkers = filteredOutlets?.map((outlet) => ({
          lat: parseFloat(outlet.Latitude),
          lng: parseFloat(outlet.Longitude),
          outletData: outlet,
        }));
        setMarkers((prevMarkers) =>
          JSON.stringify(prevMarkers) !== JSON.stringify(newMarkers)
            ? newMarkers
            : prevMarkers
        );
      }
    }
  }, [isLoaded, filteredOutlets, directions]);

  useEffect(() => {
    if (isLoaded) {
      if (directions) {
        setDirectionsState(directions);
      }
    }
    if (directions === null) {
      setDirectionsState(null);
    }
  }, [directions, isLoaded]);


  useEffect(() => {
    if (isLoaded) {
      if (currentLocation) {
        setCurrentLocationState(currentLocation);
      }
      if (directions) {
        setCurrentLocationState(null);
      }
    }
  }, [currentLocation, isLoaded, directions]);

  if (!isLoaded) return <p>Loading Maps...</p>;

  return (
    <GoogleMap
      center={
      currentLocation
      }
      zoom={14}
      mapContainerStyle={{ width: "100%", height: "100%" }}
    >
      {currentLocationState && (
        <Marker
          position={{
            lat: currentLocationState.lat,
            lng: currentLocationState.lng,
          }}
          icon={{
            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
          }}
        />
      )}

      {markers?.map((marker, index) => (
        <Marker
          key={index}
          position={{ lat: marker.lat, lng: marker.lng }}
        />
      ))}

      {directions !== null && (
        <DirectionsRenderer
          directions={directionsState}
          defaultOptions={{ suppressMarkers: true }}
        />
      )}
    </GoogleMap>
  );
};
export default MapContainer;

import {
  Box,
  CircularProgress,
  Container,
  Pagination,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import PlaceIcon from "@mui/icons-material/Place";
import {
  FaMapMarkerAlt,
  FaShoppingCart,
  FaChartLine,
  FaTag,
  FaRupeeSign,
} from "react-icons/fa";

const SlidingComponent = ({
  hasSearched,
  setIsExpanded,
  IsExpanded,
  currentAddress,
  filteredOutlets,
  setFilteredOutlets,
  currentOutlets,
  currentPage,
  totalPages,
  handlePageChange,
  wayPoints,
  handleGetRoute,
  handleSelectOutlet,
  googleMapsLink,
  distance,
  eta,
  duration,
  directions,
  selectedLeg,
  handleBack,
  selectedOutlet,
  handleOutletClick,
  toggleSection,
  openSection,
  latitude,
  setLatitude,
  longitude,
  setLongitude,
  error,
  setError,
  setUseCurrentLocation,
  handleUpdateLocation,
  isLoading,
  selectedSortOption,
}) => {
  const maxHeight = window.innerHeight * 0.8;
  const minHeight = 100;
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen((prev) => !prev);
  };
  const formatNumber = (number) => {
    return new Intl.NumberFormat("en-IN").format(number);
  };
  useEffect(() => {
    if (
      (filteredOutlets.length > 0 ||
        (filteredOutlets.length > 0 && selectedSortOption) ||
        filteredOutlets.length === 0 ||
        isLoading) &&
      hasSearched
    ) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  }, [filteredOutlets, isLoading, hasSearched, selectedSortOption]);
  return (
    <Container
      disableGutters
      sx={{
        position: "fixed",
        bottom: "50px",
        left: 0,
        right: 0,
        backgroundColor: "white",
        boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        borderRadius: "30px 30px 0 0",
        height: IsExpanded ? `${maxHeight}px` : `${minHeight}px`,
        transition: "height 0.3s ease-in-out",
        zIndex: 50,
        overflow: "auto",
        scrollbarWidth: "none",
        padding: "10px",
        border: "1px solid #80808038",
      }}
    >
      <Box sx={{ height: "80%" }}>
        <>
          {directions ? (
            <div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "3%",
                }}
              >
                <div style={{ fontFamily: "Roboto" }}>Route Overview</div>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <button
                    style={{
                      padding: "7%",
                      border: "0px",
                      borderRadius: "5px",
                      fontFamily: "Roboto",
                    }}
                    onClick={() => {
                      handleBack();
                    }}
                  >
                    back
                  </button>
                  <button
                    onClick={() => setIsExpanded(!IsExpanded)}
                    style={{
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      height: "24px",
                    }}
                  >
                    {IsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                  </button>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  padding: "2%",
                  gap: "8px",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    gap: "5px",
                  }}
                >
                  {wayPoints.length > 0 && (
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <FiberManualRecordIcon />
                      <img alt="" src={require("../assets/threedots.png")} />
                    </div>
                  )}
                  <div
                    style={{
                      width: "250px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      height: "fit-content",
                      backgroundColor: "lightgray",
                      padding: "1% 2%",
                      borderRadius: "11px",
                      fontFamily: "Roboto",
                    }}
                  >
                    Your Location
                  </div>
                </div>

                {wayPoints.map((outlet, index) => (
                  <div
                    key={outlet.Name}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      gap: "5px",
                    }}
                  >
                    {index < wayPoints.length - 1 ? (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <FiberManualRecordIcon />
                        <img alt="" src={require("../assets/threedots.png")} />
                      </div>
                    ) : (
                      <PlaceIcon />
                    )}
                    <div
                      style={{
                        width: "250px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                        height: "fit-content",
                        backgroundColor: "lightgray",
                        padding: "1% 2%",
                        borderRadius: "11px",
                        cursor: "pointer",
                        fontFamily: "Roboto",
                      }}
                      onClick={() => handleOutletClick(outlet, index)}
                    >
                      {outlet.Name}
                    </div>
                  </div>
                ))}
              </div>
              <div style={{ padding: "3%", fontSize: "15px", fontWeight: 500 }}>
                <div style={{ display: "flex", fontFamily: "Roboto" }}>
                  Total Travel Distance:
                  <div
                    style={{
                      fontWeight: 600,
                      color: "rgb(11 134 153)",
                      marginLeft: "4px",
                    }}
                  >
                    {" "}
                    {distance}{" "}
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "2%",
                    display: "flex",
                    flexWrap: "wrap",
                    fontFamily: "Roboto",
                  }}
                >
                  Estimated Drive Time:{" "}
                  <div
                    style={{
                      fontWeight: 600,
                      color: "rgb(11 134 153)",
                      marginLeft: "4px",
                    }}
                  >
                    {duration}
                  </div>
                </div>
                <div
                  style={{
                    paddingTop: "2%",
                    display: "flex",
                    flexWrap: "wrap",
                    fontFamily: "Roboto",
                  }}
                >
                  Expected Trip Completion:{" "}
                  <div
                    style={{
                      fontWeight: 600,
                      color: "rgb(11 134 153)",
                      marginLeft: "4px",
                    }}
                  >
                    {eta}
                  </div>
                </div>
              </div>
              {selectedOutlet && (
                <div style={{ marginTop: "20px" }}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "4px",
                    }}
                    onClick={() => toggleSection("table")}
                  >
                    <h4 style={{ margin: 0 }}>{selectedOutlet.Name}</h4>

                    {openSection === "table" ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                  {openSection === "table" && (
                    <TableContainer component={Paper}>
                      <Table>
                        <TableBody>
                          <TableRow>
                            <TableCell>Category Size</TableCell>
                            <TableCell>{selectedOutlet.Category}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Last Bill Date</TableCell>
                            <TableCell>
                              {new Date(
                                selectedOutlet.Last_Order_Date
                              ).toLocaleDateString()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Bill Cuts (12 months)</TableCell>
                            <TableCell>{selectedOutlet.Total_Orders}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>Bill Value (12 months)</TableCell>
                            <TableCell>
                              ₹
                              {parseFloat(
                                selectedOutlet.Total_Sales
                              ).toLocaleString("en-IN")}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>No of Days of No Billing</TableCell>
                            <TableCell>
                              {Math.floor(
                                (new Date() -
                                  new Date(selectedOutlet.Last_Order_Date)) /
                                  (1000 * 60 * 60 * 24)
                              )}{" "}
                              days
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </div>
              )}
              {selectedLeg && (
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      cursor: "pointer",
                      borderRadius: "4px",
                    }}
                    onClick={() => toggleSection("steps")}
                  >
                    <h4>Steps to Reach {selectedOutlet?.Name}</h4>
                    {openSection === "steps" ? (
                      <ExpandLessIcon />
                    ) : (
                      <ExpandMoreIcon />
                    )}
                  </div>
                  {openSection === "steps" && (
                    <div>
                      <div>
                        <strong>Start Address:</strong>{" "}
                        {selectedLeg.start_address}
                      </div>
                      <div>
                        <strong>Destination:</strong> {selectedLeg.end_address}
                      </div>
                      <ul>
                        {selectedLeg.steps.map((step, idx) => (
                          <li
                            key={idx}
                            dangerouslySetInnerHTML={{
                              __html: step.instructions,
                            }}
                          ></li>
                        ))}
                      </ul>
                    </div>
                  )}
                </div>
              )}
              {googleMapsLink && (
                <a
                  href={googleMapsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "underline",
                    color: "rgb(11 134 153)",
                    fontWeight: "bold",
                    fontFamily: "Roboto",
                  }}
                >
                  Open in Google Maps
                </a>
              )}
            </div>
          ) : (
            <>
              <Box
                sx={{
                  borderRadius: "3px",
                  flexDirection: "column",
                  display: "flex",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "1%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2%",
                      width: "80%",
                    }}
                  >
                    <Typography
                      style={{ fontWeight: "bolder", fontFamily: "Roboto" }}
                    >
                      Current Location
                    </Typography>
                    <div
                      style={{
                        cursor: "pointer",
                        padding: "3px 5px",
                        backgroundColor: "#f0f0f0",
                        fontSize: "12px",
                        borderRadius: "5px",
                        fontFamily: "Roboto",
                      }}
                      onClick={toggleModal}
                    >
                      Change Location
                    </div>
                  </div>
                  {isModalOpen && (
                    <div
                      style={{
                        position: "fixed",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "300px",
                        background: "white",
                        border: "1px solid #ccc",
                        borderRadius: "8px",
                        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                        zIndex: 1000,
                        padding: "15px",
                      }}
                    >
                      <div
                        style={{
                          marginTop: 0,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <div style={{ fontSize: "16px", fontFamily: "Roboto" }}>
                          Change Location
                        </div>
                        <button
                          onClick={() => {
                            setUseCurrentLocation(true);
                            setFilteredOutlets([]);
                            toggleModal();
                          }}
                          style={{
                            padding: "8px 5px",
                            color: "black",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                          }}
                        >
                          Use Current Location
                        </button>
                      </div>
                      <div
                        style={{
                          padding: "4%",
                          display: "flex",
                          flexDirection: "column",
                          fontFamily: "Roboto",
                        }}
                      >
                        Latitude:
                        <input
                          type="number"
                          value={latitude}
                          onChange={(e) => {
                            setLatitude(e.target.value);
                            setError("");
                          }}
                        />
                      </div>
                      <div
                        style={{
                          padding: "4%",
                          display: "flex",
                          flexDirection: "column",
                          fontFamily: "Roboto",
                        }}
                      >
                        Longitude:
                        <input
                          type="number"
                          value={longitude}
                          onChange={(e) => {
                            setLongitude(e.target.value);
                            setError("");
                          }}
                        />
                      </div>
                      {error && (
                        <div
                          style={{
                            color: "red",
                            fontSize: "12px",
                            marginTop: "8px",
                            textAlign: "center",
                          }}
                        >
                          {error}
                        </div>
                      )}
                      <div
                        style={{
                          marginTop: "12px",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <button
                          onClick={() => {
                            setUseCurrentLocation(false);
                            handleUpdateLocation();
                            setFilteredOutlets([]);
                            toggleModal();
                          }}
                          style={{
                            padding: "8px 16px",
                            color: "white",
                            backgroundColor: "#007bff",
                            border: "none",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                          }}
                        >
                          Update
                        </button>
                        <button
                          onClick={() => {
                            toggleModal();
                          }}
                          style={{
                            padding: "8px 16px",
                            color: "black",
                            backgroundColor: "#f5f5f5",
                            border: "1px solid #ccc",
                            borderRadius: "4px",
                            cursor: "pointer",
                            fontFamily: "Roboto",
                          }}
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  )}
                  {(filteredOutlets?.length > 0 ||
                    filteredOutlets?.length === 0) &&
                    hasSearched && (
                      <button
                        onClick={() => setIsExpanded(!IsExpanded)}
                        style={{
                          border: "none",
                          borderRadius: "5px",
                          cursor: "pointer",
                          height: "24px",
                        }}
                      >
                        {IsExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                      </button>
                    )}
                </div>

                <Box style={{ fontFamily: "Roboto" }}>
                  {currentAddress || "Loading address..."}
                </Box>
              </Box>

              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                  }}
                >
                  <CircularProgress size="2rem" sx={{ color: "red" }} />
                </div>
              ) : filteredOutlets?.length === 0 && hasSearched ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "100%",
                    color: "red",
                    fontSize: "20px",
                    fontWeight: "bolder",
                    fontFamily: "Roboto",
                  }}
                >
                  No outlets found
                </div>
              ) : (
                <div style={{ padding: "3%" }}>
                  {filteredOutlets?.length > 0 && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        fontWeight: 600,
                        fontFamily: "Roboto",
                      }}
                    >
                      Outlets :
                      {wayPoints.length !== 0 && (
                        <button
                          style={{
                            padding: "2%",
                            border: "1px solid black",
                            borderRadius: "5px",
                            fontFamily: "Roboto",
                          }}
                          onClick={handleGetRoute}
                        >
                          Get Route
                        </button>
                      )}
                    </div>
                  )}
                  {currentOutlets?.map((outlet) => (
                    <div
                      key={outlet.Name}
                      style={{
                        border: wayPoints?.includes(outlet)
                          ? "2px solid black"
                          : "1px solid #ddd",
                        padding: "6px 9px 9px",
                        margin: "8px 0",
                        borderRadius: "8px",
                        backgroundColor: "#fff",
                        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                      }}
                    >
                      {/* Header: Outlet Name & Checkbox */}
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <strong
                          style={{
                            fontSize: "15px",
                            color: "#333",
                            fontFamily: "Roboto",
                          }}
                        >
                          {outlet.Name}
                        </strong>
                        <input
                          type="checkbox"
                          checked={wayPoints?.includes(outlet)}
                          onChange={() => handleSelectOutlet(outlet)}
                          style={{
                            width: "15px",
                            height: "15px",
                            cursor: "pointer",
                          }}
                        />
                      </div>

                      <div
                        style={{
                          fontSize: "14px",
                          color: "#444",
                          lineHeight: "1",
                          fontFamily: "Roboto",
                        }}
                      >
                        <p>
                          <FaMapMarkerAlt
                            style={{ color: "#ff5733", marginRight: "5px" }}
                          />{" "}
                          Distance: <strong>{outlet.Distance}</strong> | Last
                          Order: <strong>{outlet.Last_Order_Date}</strong>
                        </p>
                        <p>
                          <FaChartLine
                            style={{ color: "#27ae60", marginRight: "5px" }}
                          />{" "}
                          Probability:{" "}
                          <strong>{outlet.orderProbability}%</strong> | Next
                          Order: <strong>₹{outlet.averageSale}</strong>{" "}
                        </p>
                        <div>
                          <FaTag
                            style={{ color: "#8e44ad", marginRight: "5px" }}
                          />{" "}
                          Category: <strong>{outlet.Category}</strong> | YTD:{" "}
                          <strong>₹{formatNumber(outlet.Total_Sales)}</strong>
                        </div>
                      </div>
                    </div>
                  ))}

                  {filteredOutlets?.length > 0 &&
                    totalPages &&
                    totalPages > 1 && (
                      <Pagination
                        count={totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                        color="primary"
                        style={{
                          marginTop: "20px",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      />
                    )}
                </div>
              )}
            </>
          )}
        </>
      </Box>
    </Container>
  );
};
export default SlidingComponent;
